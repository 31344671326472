<template>
  <q-layout view="lHh Lpr lFf">
    <q-header class="bg-white">
      <q-toolbar
        class="bg-white q-py-sm q-px-md q-mx-auto"
        style="max-width: 1440px; min-width: 300px"
      >
        <q-toolbar-title>
          <q-img :src="`./img/chewee-color.png`" width="100px"> </q-img>
        </q-toolbar-title>
        <div class="row items-center q-gutter-x-sm" v-if="checkAdmin">
          <q-input
            outlined
            dense
            label="Vendor ID"
            v-model="data.vendor_id"
          ></q-input>
          <q-btn
            dense
            class="q-px-sm"
            no-caps
            label="Switch"
            color="brand"
            unelevated
            @click="swithUser"
          ></q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-page-container
      class="my-font l-mobile-container"
      style="background-color: #f4f4f4"
    >
      <router-view class="my-font" />
    </q-page-container>
    <q-dialog v-model="isDialogOpen">
      <q-card>
        <q-card-section>Dialog HP</q-card-section>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import useBasic from "./composables/useBasic";
export default {
  name: "LayoutDefault",

  setup() {
    const {
      escapePhone,
      $http,
      route,
      onMounted,
      isDialogOpen,
      computed,
      reactive,
    } = useBasic();

    let data = reactive({
      vendor_id: "",
    });

    onMounted(async () => {
      await getVendor();
    });

    let getVendor = async () => {
      let id = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
      data.vendor_id = atob(id);
    };

    let checkAdmin = computed(() => {
      let pass = localStorage.getItem("l_admin_access");
      if (pass) {
        if (btoa(pass) == "TGlnbm9zYyMxIzU0") {
          return true;
        }
      }
      return false;
    });

    let swithUser = () => {
      let token = btoa(data.vendor_id);
      localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, token);
      window.location.reload();
    };

    return {
      leftDrawerOpen: ref(false),
      isDialogOpen,
      checkAdmin,
      data,
      swithUser,
    };
  },
};
</script>
<style lang="scss">
.stickyTable {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: white;
    color: black;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
    border-right: none !important;
    border-left: none !important;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
  td {
    vertical-align: top;
  }
}
.l-mobile-container {
  max-width: 1440px;
  min-width: 300px;
  height: calc(100vh - 50px);

  margin: 0 auto;
}
.bg-brand {
  background: #e6037d !important;
}

.text-brand {
  color: #e6037d !important;
}
@font-face {
  font-family: barcode;
  src: url(./styles/fonts/barcode.TTF);
}

@font-face {
  font-family: code128;
  src: url(./styles/fonts/code128.ttf);
}

@font-face {
  font-family: customfont;
  src: url(./styles/fonts/Sora-Regular.ttf);
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Sora-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Sora-SemiBold.ttf");
  font-weight: 500;
}

.barcode-font {
  font-family: "barcode" !important;
}

.barcode-128 {
  font-family: "code128" !important;
}

.my-font {
  font-family: "customfont";
}

.f10 {
  font-size: 10pt;
}
.f12 {
  font-size: 12pt;
}
.f14 {
  font-size: 14pt;
}
.f16 {
  font-size: 16pt;
}
.f18 {
  font-size: 18pt;
}
.f20 {
  font-size: 20pt;
}
.kiri-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 0 0 8px !important;
}

.kanan-radius {
  border: 1px solid #bbbbbb;
  border-radius: 0 8px 8px 0 !important;
}
.atas-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 8px 0 0 !important;
}
.bawah-radius {
  border: 1px solid #bbbbbb;
  border-radius: 0 0 8px 8px !important;
}

.all-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 8px 8px 8px !important;
}

.l-grow {
  flex-grow: 99;
}
</style>
