import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import {
  ref,
  reactive,
  inject,
  watch,
  onMounted,
  computed,
  onBeforeUnmount,
  watchEffect,
} from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";
moment.locale("id");

export default function useBasic() {
  const router = useRouter();
  const route = useRoute();
  const $http = inject("$http");
  const thousand = inject("thousand");
  const rupiah = inject("rupiah");
  const $q = useQuasar();

  let isLoadingData = ref(false);
  let loadThis = async (fun) => {
    isLoadingData.value = true;
    await fun();
    isLoadingData.value = false;
  };
  let waitThis = async (fun) => {
    $q.loading.show();
    await fun();
    $q.loading.hide();
  };

  async function extractToArray(data, key) {
    return data.map((a) => a[key]);
  }

  async function getDistinctAOO(data, key) {
    let resp = await new Promise(async (resolve, reject) => {
      let result = [...new Set(await data.map((item) => item[key]))];
      resolve(result);
    });
    return resp;
  }

  async function sumArrayofObject(arr, key) {
    let resp = await new Promise((resolve, reject) => {
      var sum = arr.reduce((accumulator, currentValue) => {
        if (currentValue[key]) return accumulator + parseInt(currentValue[key]);
        else return accumulator + 0;
      }, 0);
      resolve(sum);
    });
    return resp;
  }

  async function escapePhone(num) {
    let resp = await new Promise((resolve, reject) => {
      var value = num.slice(0);
      var mobile = "";

      //First remove all spaces:
      value = value.replace(/\s/g, "");

      //Second remove all "-":
      value = value.replace(/-/g, "");

      // If there is a countrycode, this IF will remove it..
      // If there is a countrycode, this IF will remove it..
      if (value.startsWith("+")) {
        var temp = value.substring(3, value.length);
        mobile = "0" + temp;
      } else if (value.startsWith("6")) {
        var temp = value.substring(2, value.length);
        mobile = "0" + temp;
      }

      // If there is no countrycode, only remove spaces
      else {
        mobile = value;
      }

      resolve(mobile);
    });

    return resp;
  }

  let isDialogOpen = ref(false);

  return {
    router,
    route,
    $http,
    thousand,
    rupiah,
    $q,
    moment,
    isLoadingData,
    ref,
    reactive,
    watch,
    onMounted,
    computed,
    loadThis,
    waitThis,
    onBeforeUnmount,
    onBeforeRouteLeave,
    watchEffect,
    extractToArray,
    getDistinctAOO,
    sumArrayofObject,
    escapePhone,
    isDialogOpen,
  };
}
