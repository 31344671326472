import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/Home.vue";
import axios from "axios";
import useBasic from "@/composables/useBasic";
const routes = [
  {
    path: "/",
    name: "List",
    component: HomeView,
  },
  {
    path: "/receipt/:id?",
    name: "Receipt",
    component: () => import("../views/Receipt.vue"),
  },
  {
    path: "/phoneAuth",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/phoneAuth"),
  },
  {
    path: "/noAuth",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/NoID"),
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/ErrorNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { isDialogOpen, $http, $q } = useBasic();
  $q.loading.show({ message: "Redirecting..." });
  let vendor_id = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  // let vendor_id = 169;
  let q_vendor_id = to.query.vendor_id;

  try {
    if (to.meta.noAuth) {
      $q.loading.hide();
      next();
    } else {
      if (vendor_id && q_vendor_id) {
        if (vendor_id != q_vendor_id) {
          $q.loading.hide();
          localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
          next(`/phoneAuth?vendor_id=${q_vendor_id}`);
        } else {
          $q.loading.hide();
          next();
        }
      } else if (vendor_id) {
        let resp = await $http.get(`/chewee/seller/${vendor_id}`);
        if (resp.data.id) {
          $q.loading.hide();
          next();
        } else {
          localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
          $q.loading.hide();
          next("/noAuth");
        }
      } else if (q_vendor_id) {
        $q.loading.hide();
        next(`/phoneAuth?vendor_id=${q_vendor_id}`);
      } else {
        $q.loading.hide();
        next("/noAuth");
      }
    }
    // next();
  } catch (err) {
    console.log(err);
    $q.loading.hide();
    next("/noAuth");
  }
});

// router.beforeEach(async (to, from, next) => {
//   let temp_token = null;
//   if (process.env.NODE_ENV == "development") {
//     // RND
//     // temp_token =
//     //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMaWdubyBTb2Z0d2FyZSBTb2x1dGlvbiIsImV4cCI6MTY0NTg1NzIyNywiZGF0YSI6eyJpZCI6IjE0IiwidXNlcm5hbWUiOiJybmQiLCJkZXBhcnRtZW50IjoiUk5EIiwiaXNfbWFuYWdlciI6IjEiLCJpc19zdGFmZiI6IjAifX0.0ZgAqdzIfN1Y2M6XESqZhtT_rGf25BkNDrs6wzr8GUg";

//     // PRODUCTION
//     // temp_token =
//     //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMaWdubyBTb2Z0d2FyZSBTb2x1dGlvbiIsImV4cCI6MTY0NTg1NzMxOCwiZGF0YSI6eyJpZCI6IjE3IiwidXNlcm5hbWUiOiJwcm9kdWN0aW9uIiwiZGVwYXJ0bWVudCI6IlBST0RVQ1RJT04iLCJpc19tYW5hZ2VyIjoiMCIsImlzX3N0YWZmIjoiMSJ9fQ.bftN7BmQ3nJViNcVdz9Q7Gr_r05y7t14UykS96gsfdI";

//     //QC
//     temp_token =
//       "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMaWdubyBTb2Z0d2FyZSBTb2x1dGlvbiIsImV4cCI6MTY5ODE5NTQ3MCwiZGF0YSI6eyJpZCI6IjIiLCJ1c2VybmFtZSI6ImFsZ2hpIiwibmFtZSI6Ik11aGFtbWFkIEFsIEdoaWZmYXJpIiwiZGVwYXJ0bWVudCI6IlFDIiwiaXNfbWFuYWdlciI6IjAiLCJpc19zdGFmZiI6IjEiLCJhcHBfc2FsZXNfaXNfc3VwZXJ2aXNlZCI6IjAifX0.71-h7EAGbOKlaMcwisUMIzvmMpJHCSh0Gkol0pJRoIM";

//     // ADMIN
//     // temp_token =
//     //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMaWdubyBTb2Z0d2FyZSBTb2x1dGlvbiIsImV4cCI6MTY5Nzk0MDQ2NywiZGF0YSI6eyJpZCI6IjEiLCJ1c2VybmFtZSI6ImFkbWluIiwibmFtZSI6IkFkbWluaXN0cmF0b3IiLCJkZXBhcnRtZW50IjoiQURNSU5JU1RSQVRPUiIsImlzX21hbmFnZXIiOiIxIiwiaXNfc3RhZmYiOiIwIiwiYXBwX3NhbGVzX2lzX3N1cGVydmlzZWQiOiIwIn19.UCzATUhvlC9hQ0VIIA2pRdg5OeEIyrpyENIoeF12VnI";

//     localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, temp_token);
//   }

//   let token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
//   if (to.meta.noAuth) {
//     next();
//   } else if (token === null) {
//     // console.log("no token");
//     window.location.href = process.env.VUE_APP_HOME + "/#/login";
//   } else {
//     try {
//       let resp = await axios.get(`${process.env.VUE_APP_BASE_URL}/user_data`, {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       });
//       to.meta.user = resp.data;
//       if (to.meta?.access == null) {
//         next();
//       } else {
//         let access = checkAccess(resp.data, to.meta.access);
//         if (access) {
//           next();
//         } else {
//           next("/noaccess");
//         }
//       }
//     } catch (error) {
//       console.log(error);
//       console.log("gagal menarik data user");
//       localStorage.clear();
//       // next("/");
//       window.location.href = process.env.VUE_APP_HOME + "/#/login";
//     }
//   }
// });

export default router;
