<template>
  <q-page>
    <q-card flat>
      <q-card-section class="row justify-between items-center">
        <div class="text-h5 text-bold">Requested Pickup Order</div>
        <div class="row items-center q-gutter-x-sm">
          <q-btn
            dense
            outline
            icon="filter_list"
            class="q-pa-sm"
            @click="
              data.showFilter = JSON.parse(JSON.stringify(data.realFilter));
              dialogFilter = true;
            "
          ></q-btn>
          <div class="row">
            <q-input
              dense
              square
              class="kiri-radius"
              outlined
              placeholder="Search by Order ID"
              style="width: 300px"
              v-model="data.search"
              clearable
            ></q-input>
            <q-btn
              dense
              square
              unelevated
              color="grey-10"
              text-color="white"
              class="q-px-sm kanan-radius"
              label="Search"
              no-caps
              @click="loadThis(refreshList)"
            ></q-btn>
          </div>
          <q-btn
            dense
            unelevated
            color="brand"
            label="Add Request"
            class="q-pa-sm"
            no-caps
            to="/receipt"
          ></q-btn>
        </div>
      </q-card-section>
      <q-card-section>
        <q-markup-table
          bordered
          flat
          square
          wrap-cells
          class="stickyTable"
          separator="cell"
          style="height: calc(100vh - 240px)"
        >
          <thead>
            <tr>
              <th>No.</th>
              <th>ID Order</th>
              <th>Kurir</th>
              <th>Pengirim & Alamat</th>
              <th>Tanggal Order</th>
              <th>Penerima & Alamat</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in data.receiptList" :key="i">
              <td>
                {{
                  (data.pagination.current - 1) * data.pagination.limit +
                  (i + 1)
                }}
              </td>
              <td style="width: 150px">
                {{ val.prefix_id }}
              </td>
              <td>
                <div class="column q-gutter-y-xs">
                  <div>{{ val.shipment.toUpperCase() }}</div>
                  <div class="text-grey-6">{{ val.data.no_resi }}</div>
                </div>
              </td>
              <td>
                <div class="column q-gutter-y-xs">
                  <div>{{ val.data.nama_pengirim }}</div>
                  <div class="text-grey-6">
                    {{ val.data.alamat_pengirim }}
                  </div>
                  <div class="text-grey-6">({{ val.data.no_pengirim }})</div>
                </div>
              </td>
              <td style="width: 175px" class="text-center">
                {{
                  `${moment(val.pickup_date).format("dddd, DD MMM YYYY")} ${
                    val.shipment == "jnt"
                      ? `${val.pickup_time_from.slice(
                          0,
                          -3
                        )} - ${val.pickup_time_to.slice(0, -3)}`
                      : `${val.pickup_time_from.slice(0, -3)}`
                  }`
                }}
              </td>
              <td>
                <div class="column q-gutter-y-xs">
                  <div>{{ val.data.nama_penerima }}</div>
                  <div class="text-grey-6">
                    {{ val.data.alamat_penerima }}
                  </div>
                  <div class="text-grey-6">({{ val.data.no_penerima }})</div>
                </div>
              </td>
              <td class="text-center" style="width: 150px">
                <q-btn
                  flat
                  dense
                  color="accent"
                  label="Detail"
                  no-caps
                  icon="feed"
                  :to="`/receipt/${val.id}`"
                ></q-btn>
              </td>
            </tr>
          </tbody>
          <q-inner-loading :showing="isLoadingData">
            <q-spinner-gears size="50px" color="primary" />
          </q-inner-loading>
        </q-markup-table>
      </q-card-section>
      <q-card-actions align="center">
        <q-pagination
          input
          :max="data.pagination.max"
          v-model="data.pagination.current"
          @update:model-value="loadThis(refreshList)"
          :disable="isLoadingData"
        ></q-pagination>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="dialogFilter" position="bottom" persistent>
      <q-card>
        <q-card-section class="row justify-between items-center q-pb-none">
          <div class="text-h6 text-bold">Filter</div>
          <q-btn flat dense icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="column q-gutter-y-sm">
          <!-- <div class="q-gutter-y-xs">
            <div class="text-bold">Status</div>
            <q-radio
              v-model="data.showFilter.status"
              val="all"
              label="All"
            ></q-radio>
            <q-radio
              v-model="data.showFilter.status"
              val="belum_cetak"
              label="Belum di Cetak"
            ></q-radio>
            <q-radio
              v-model="data.showFilter.status"
              val="sudah_cetak"
              label="Sudah di Cetak"
            ></q-radio>
          </div> -->
          <div class="q-gutter-y-xs">
            <div class="text-bold">Kurir</div>
            <q-radio
              v-model="data.showFilter.kurir"
              val="all"
              label="All"
            ></q-radio>
            <q-radio
              v-model="data.showFilter.kurir"
              val="jnt"
              label="JNT"
            ></q-radio>
            <q-radio
              v-model="data.showFilter.kurir"
              val="sicepat"
              label="Sicepat"
            ></q-radio>
          </div>
          <div class="q-gutter-y-xs">
            <div class="text-bold">Tanggal Order</div>
            <q-input
              dense
              outlined
              readonly
              v-model="data.showFilter.from"
              placeholder="Dari Tanggal"
            >
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="data.showFilter.from">
                  <div class="row items-center justify-between">
                    <q-btn
                      v-close-popup
                      label="Reset"
                      color="primary"
                      @click="data.showFilter.from = ''"
                      flat
                      no-caps
                    />
                    <q-btn
                      v-close-popup
                      label="Apply"
                      color="primary"
                      unelevated
                      no-caps
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-input>
            <q-input
              dense
              outlined
              readonly
              v-model="data.showFilter.to"
              placeholder="Sampai Tanggal"
            >
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="data.showFilter.to">
                  <div class="row items-center justify-between">
                    <q-btn
                      v-close-popup
                      label="Reset"
                      color="primary"
                      flat
                      no-caps
                      @click="data.showFilter.to = ''"
                    />
                    <q-btn
                      v-close-popup
                      label="Apply"
                      color="primary"
                      unelevated
                      no-caps
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-input>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            unelevated
            color="brand"
            dense
            label="Terapkan"
            no-caps
            class="q-pa-sm"
            @click="
              data.realFilter = JSON.parse(JSON.stringify(data.showFilter));
              data.search = '';
              loadThis(refreshList);
              dialogFilter = false;
            "
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import useBasic from "@/composables/useBasic";
export default {
  setup() {
    const {
      reactive,
      ref,
      moment,
      thousand,
      rupiah,
      loadThis,
      isLoadingData,
      $http,
      onMounted,
      route,
    } = useBasic();

    let data = reactive({
      showFilter: {
        from: "",
        to: "",
        status: "all",
        kurir: "all",
      },
      realFilter: {
        from: "",
        to: "",
        status: "all",
        kurir: "all",
      },
      pagination: {
        current: 1,
        limit: 25,
        max: 1,
      },
      search: "",
      receiptList: [],
    });

    let searchOrder = async () => {};

    let dialogFilter = ref(false);

    onMounted(async () => {
      data.showFilter = {
        from: "",
        to: "",
        status: "all",
        kurir: "all",
      };
      data.realFilter = {
        from: "",
        to: "",
        status: "all",
        kurir: "all",
      };
      await loadThis(refreshList);
    });

    let refreshList = async () => {
      let vendor_id = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
      if (vendor_id) {
        let resp = await $http.get(
          `/chewee/list/${vendor_id}?limit=${data.pagination.limit}&current=${data.pagination.current}&search=${data.search}&kurir=${data.realFilter.kurir}&from=${data.realFilter.from}&to=${data.realFilter.to}`
        );
        data.receiptList = resp.data.rows;
        data.pagination.max = Math.ceil(
          resp.data.max_rows / data.pagination.limit
        );
      }
    };

    return {
      data,
      moment,
      dialogFilter,
      loadThis,
      isLoadingData,
      searchOrder,
      refreshList,
    };
  },
};
</script>

<style></style>
