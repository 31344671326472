import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";
import VueBarcode from "@chenfengyuan/vue-barcode";

const app = createApp(App);

app.use(router);
app.use(Quasar, {
  plugins: {
    Notify,
    Loading,
    Dialog,
  },
  config: {
    loading: {},
  },
  quasarUserOptions,
});

app.component(VueBarcode.name, VueBarcode);

// provide
app.provide(
  "$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);

app.provide("rupiah", (number) => {
  return Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 2,
  }).format(number);
});

app.provide("thousand", (number) => {
  return Intl.NumberFormat("id").format(number);
});
// end of provide

app.mount("#app");
